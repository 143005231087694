import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { TranslateService } from '@ngx-translate/core';
import { Mutex } from 'async-mutex';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription, firstValueFrom } from 'rxjs';

import { Constants } from '../../../../wdcommon/Constants';
import { CartItemType, CartSubType, ICartItem, IPurewoodFrontOptions, IPurewoodPaintedFrontOptions, RepeatCartItem } from '../../../../wdcommon/ICartItem';
import { CreateOrder, ExistingOrder, FeeTypes, ICart, IExternalShopOrder, OrderFee } from '../../../../wdcommon/IOrder';
import { IFreight } from '../../../../wdcommon/IPrices';
import { FrontCategory, OptionProperty } from '../../../../wdcommon/IProduct';
import { IIFreightSetting } from '../../../../wdcommon/ISetting';
import { IUser } from '../../../../wdcommon/IUser';
import { TranslatingBase } from '../base-component/ComponentBase';
import { IOrderLayoutViewMode } from '../order-layout/iorder-layout';
import { APIService, CartService, PriceCalculatorService, SettingService, UserService } from '../services';
import { defaultBreadcrumbs, productsUrl } from '../utils/breadcrumbs/breadcrumbs';


@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
})
export class CartComponent extends TranslatingBase implements OnInit, OnDestroy {
  altLevering = false;
  acceptedTermsAndConditions = false;
  stdLevAdr: {
    name: string,
    address: string,
    address2: string,
    postcode: number,
    city: string
  };
  altLevAdr: {
    name: string,
    address: string,
    address2: string,
    postcode: string,
    city: string
  } = {
      name: '',
      address: '',
      address2: '',
      postcode: '',
      city: ''
    };

  altLeveringErrors: { name?: string, address?: string, postcode?: string, city?: string } = {};

  cartSubscription: Subscription;
  cart: ICart;
  drawers: ICartItem[] = [];
  runner: ICartItem[] = [];
  extras: ICartItem[] = [];
  fronts: ICartItem[] = [];
  carcass: ICartItem[] = [];
  additionalItems: ICartItem[] = [];
  minDate = new Date();
  bsDeliveryDate: any;

  ecoNumber: number;

  saveName = null;
  clearOnSave = false;

  isCreatingOrder = false;

  comments: string;
  requisition: string;

  freightSetting: IIFreightSetting;

  productsTotal = 0;
  orderTotalNoVat = 0;
  freightTotal: IFreight;
  oneDelivery: number;
  oneDeliveryFreight = 0;

  colours: { colour: string; totalAmount: number }[] = [];

  modalRef: BsModalRef;

  user: IUser;
  adminNotheggerAdjust: number;

  doNotDelete = false;
  ecoCustomer = '';

  repeatDrawer: RepeatCartItem;
  repeatPurewoodFront: RepeatCartItem;

  mutex = new Mutex();

  protected readonly faCheck = faCheck;
  protected readonly faEdit = faEdit;
  protected readonly faSave = faSave;
  protected readonly faTrash = faTrash;
  protected readonly faSpinner = faSpinner;
  protected readonly productsUrl = productsUrl;
  protected readonly Constants = Constants;
  protected readonly cartBreadcrumb = defaultBreadcrumbs.cart;
  protected readonly IOrderLayoutViewMode = IOrderLayoutViewMode;
  protected readonly window = window;

  constructor(
    public cartService: CartService,
    public modalService: BsModalService,
    private apiService: APIService,
    private price: PriceCalculatorService,
    private router: Router,
    private settingService: SettingService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private userService: UserService,
  ) {
    super(translateService);
  }

  get canPurchase() {
    return (this.user && this.user.activated) && !this.isCreatingOrder
  }

  async ngOnInit() {
    this.user = undefined;
    this.freightSetting = await this.settingService.getFreightSetting();
    if (this.userService.isLoggedIn) {
      this.user = this.userService.getUser();
      await this.cartService.publishNewCart();
    }

    if (this.cartService.getNumberOfItems() === 0) {
      await this.router.navigateByUrl('/');
      return;
    }

    this.minDate.setDate(this.minDate.getDate() + 21); // Earliest delivery date is in three weeks

    if (this.canPurchase) {
      const company = this.user.company;

      this.stdLevAdr = company.deliveryAddress
        ? {
          name: company.companyName,
          address: company.deliveryAddress,
          address2: company.deliveryAddress2,
          city: company.deliveryCity,
          postcode: company.deliveryPostcode
        }
        : {
          name: company.companyName,
          address: company.address,
          address2: company.address2,
          city: company.city,
          postcode: company.postcode
        };
    }

    await this.recalculateTotals();

    this.cartSubscription = this.cartService.connectItems()
      .subscribe((cart) => {
        if (!cart || cart.length === 0) {
          return this.router.navigateByUrl('/');
        }

        this.cart = cart.sort((a, b) => a.type < b.type ? 1 : a.type > b.type ? -1 : 0);
        this.drawers = cart.filter((i) => i.type === CartItemType.drawer);
        this.runner = cart.filter((i) => [CartItemType.other, CartItemType.hettichRunnerAddOn, CartItemType.runner].includes(i.type));
        this.extras = cart.filter((i) => i.type === CartItemType.extra);
        this.fronts = cart.filter((i) => i.type === CartItemType.fronts);
        this.carcass = cart.filter((i) => i.type === CartItemType.carcass);
        this.additionalItems = cart.filter((i) => i.type === CartItemType.additional);

        this.recalculateTotals();
      });

    this.adminNotheggerAdjust = this.userService.getAdminNotheggerAdjust();

    window.addEventListener('beforeprint', this.beforePrint.bind(this));
    window.addEventListener('afterprint', this.afterPrint.bind(this));
  }

  ngOnDestroy() {
    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
    this.toastrService.clear();
    window.removeEventListener('beforeprint', this.beforePrint.bind(this));
    window.removeEventListener('afterprint', this.afterPrint.bind(this));
  }

  async setEcoNr() {
    const ecoCompany = await firstValueFrom(this.apiService.getCompanyFromEconomics(this.ecoNumber));
    this.ecoCustomer = ecoCompany as string;
  }

  saveOrder() {
    this.apiService
      .postSavedOrder({
        cart: this.cartService.export(),
        name: this.saveName,
      })
      .subscribe(async () => {
        this.modalRef.hide();
        this.toastrService.success('Ordren er gemt...');
        if (this.clearOnSave) {
          await this.cartService.clearCart();
          await this.router.navigateByUrl('/');
        }
      });
  }

  private async beforePrint() {
    const viewMoreButtons = document.querySelectorAll<HTMLButtonElement>('.view-more-button.collapsed');
    viewMoreButtons.forEach((button) => button.click())
  }

  private async afterPrint() {
    const viewMoreButtons = document.querySelectorAll<HTMLButtonElement>('.view-more-button.expanded');
    viewMoreButtons.forEach((button) => button.click())
  }

  async recalculateTotals() {
    await this.mutex.runExclusive(async () => {
      if (!this.canPurchase) {
        return;
      }

      this.orderTotalNoVat = 0;
      this.productsTotal = 0;

      let oneDeliveryUnits = 0;
      let oneDeliveryUnitsXl = 0;
      let oneDeliveryPallets = 0;

      this.drawers.forEach((d) => {
        // console.log('calculating', d);
        this.orderTotalNoVat += d.priceTotal;
        this.productsTotal += d.priceTotal;

        // OneDelivery
        oneDeliveryUnits += d.amount;
      });
      this.runner.forEach((hettichRunner) => {
        this.orderTotalNoVat += hettichRunner.priceTotal;
        this.productsTotal += hettichRunner.priceTotal;

        // OneDelivery
        if (
          ![CartItemType.hettichRunnerAddOn, CartItemType.runner].includes(hettichRunner.type) &&
          !['9257706', '9246315', '9257892', '9257893', '9268684', '9320333', '9257268', '9247529', '9144597', '9117492'].includes(hettichRunner.itemno)
        ) {
          console.log('Unhandled other, not a known hettich product', hettichRunner);
        }
      });
      this.extras.forEach((e) => {
        this.orderTotalNoVat += e.priceTotal;
        this.productsTotal += e.priceTotal;

        // OneDelivery
        const oneDeliverySubTypes = [
          CartSubType.bestikskuffe,
          CartSubType.slacksDrawer,
          CartSubType.solidOrganiser,
          CartSubType.sortissimoFastline,
          CartSubType.trashcanDrawer,
          CartSubType.careSet
        ];
        if (![CartSubType.afstandsliste, ...oneDeliverySubTypes].includes(e.subType)) {
          console.log('Unhandled extras', e);
        }
        if (oneDeliverySubTypes.includes(e.subType)) {
          oneDeliveryUnits += e.amount;
        }
      });

      const tmpColours: { [colour: string]: number } = {};
      this.fronts.forEach((e) => {
        if ((e.options as IPurewoodFrontOptions).category === FrontCategory.painted && (e.options as IPurewoodPaintedFrontOptions).colour) {
          tmpColours[e.options.colour] = (tmpColours[e.options.colour] ?? 0) + e.priceTotal;
        }
        this.orderTotalNoVat += e.priceTotal;
        this.productsTotal += e.priceTotal;

        // OneDelivery
        if ((e.options as IPurewoodFrontOptions)[OptionProperty.height] > this.freightSetting.oneDelivery.doublePalletLimit
          || (e.options as IPurewoodFrontOptions)[OptionProperty.width] > this.freightSetting.oneDelivery.doublePalletLimit) {
          oneDeliveryUnitsXl += e.amount;
        } else {
          oneDeliveryUnits += e.amount;
        }
      });

      this.carcass.forEach((e) => { // Does not have a price
        this.orderTotalNoVat += e.priceTotal || 0;
        this.productsTotal += e.priceTotal || 0;

        // Carcass - not handled by OneDelivery
      });

      this.additionalItems.forEach((a) => {
        this.orderTotalNoVat += a.priceTotal;
        this.productsTotal += a.priceTotal;

        // OneDelivery
        if (typeof a.oneDeliverySize !== 'number' || typeof a.oneDeliveryDoublePallet !== 'boolean') {
          console.log('Unhandled additionalItem', a);
        }
        if (a.oneDeliveryDoublePallet) {
          if (a.oneDeliverySize === 255) {
            oneDeliveryPallets += 2 * a.amount;
          } else {
            oneDeliveryUnitsXl += a.oneDeliverySize * a.amount;
          }
        } else {
          if (a.oneDeliverySize === 255) {
            oneDeliveryPallets += a.amount;
          } else {
            oneDeliveryUnits += a.oneDeliverySize * a.amount;
          }
        }
      });

      let postalCode: number;
      if (this.altLevering && parseInt(this.altLevAdr.postcode, 10) > 0) {
        postalCode = parseInt(this.altLevAdr.postcode, 10);
      } else if (this.user.company.deliveryPostcode) {
        postalCode = this.user.company.deliveryPostcode;
      } else if (this.user.company.postcode) {
        postalCode = this.user.company.postcode;
      }

      this.freightTotal = await this.price.CalculateFreight({
        drawers: this.drawers,
        [CartItemType.runner]: this.runner,
        extras: this.extras,
        additionals: this.additionalItems,
        [CartItemType.fronts]: this.fronts,
        [CartItemType.carcass]: this.carcass,
      }, this.user.company.freightTier, postalCode);

      if (this.freightTotal.additionalsBreakageFees && this.freightTotal.additionalsBreakageFees.count)
        this.orderTotalNoVat += this.freightTotal.additionalsBreakageFees.count * this.freightTotal.additionalsBreakageFees.price;

      if (this.freightTotal.additionalsFreight)
        this.freightTotal.additionalsFreight.map((freight) => this.orderTotalNoVat += freight.freightRate);

      this.orderTotalNoVat += this.freightTotal.basePrice;

      if (this.freightTotal.hasFronts) {
        this.orderTotalNoVat += this.freightTotal.frontsAddition;

        this.colours = [];
        Object.entries(tmpColours).forEach(([colour, totalAmount]) => {
          if (totalAmount < this.freightSetting.purewood.colourStartup.threshold) {
            this.colours.push({ colour: colour, totalAmount: totalAmount });
            if (this.user && this.user.company.freightTier) {
              this.orderTotalNoVat += this.freightSetting.purewood.colourStartup.fee[this.user.company.freightTier];
            }
          }
        });
      }

      // Calculate OneDelivery Freight
      if (oneDeliveryUnits > 0 || oneDeliveryUnitsXl > 0 || oneDeliveryPallets > 0) {
        let pallets = Math.ceil(oneDeliveryUnitsXl / (this.freightSetting.oneDelivery.palletCapacity * 2)) * 2;
        const freeRoomOnPallet = this.freightSetting.oneDelivery.palletCapacity * pallets - oneDeliveryUnitsXl;
        if ((oneDeliveryUnits - freeRoomOnPallet) > 0) {
          pallets += Math.ceil((oneDeliveryUnits - freeRoomOnPallet) / this.freightSetting.oneDelivery.palletCapacity);
        }
        pallets += oneDeliveryPallets;
        if (this.user && this.user.company.freightTier) {
          this.oneDeliveryFreight = pallets * this.freightSetting.oneDelivery.palletFreight[this.user.company.freightTier];
        }
        if (this.oneDelivery) {
          this.oneDelivery = this.oneDeliveryFreight;
        }
      } else {
        this.oneDeliveryFreight = 0;
        this.oneDelivery = 0;
      }

      if (this.oneDelivery > 0) {
        this.orderTotalNoVat += this.oneDelivery;
      }

      if (this.freightTotal.told) {
        this.orderTotalNoVat += this.freightTotal.told;
      }
    });
  }

  confirmAndSendExistingOrder() {
    try {
      const order: ExistingOrder = { ...this.prepareOrder(), id: this.cartService.getExistingCart().id };
      this.apiService.postExistingOrder(order).subscribe((res) => this.handleOrderResponse(res));
    } catch (err) {
      console.error('Order update confirm error', err);
      this.toastrService.error(this.translate('CART.orderUpdateError.message'), this.translate('CART.orderUpdateError.title'), { timeOut: 10000 });
    }

  }

  async confirmAndSendNewOrder() {
    if (!this.cartService.checkCanAddToCartAndDisplayError()) {
      return;
    }

    if (this.altLevering) {
      this.altLeveringErrors = {};
      if (!this.altLevAdr.name) this.altLeveringErrors.name = this.translate('CART.Validation.Required');
      if (!this.altLevAdr.address) this.altLeveringErrors.address = this.translate('CART.Validation.Required');
      if (!this.altLevAdr.postcode) this.altLeveringErrors.postcode = this.translate('CART.Validation.Required');
      if (!this.altLevAdr.city) this.altLeveringErrors.city = this.translate('CART.Validation.Required');

      if (Object.keys(this.altLeveringErrors).length > 0) {
        this.toastrService.error(this.translate('CART.Validation.MissingFieldsInAlternativeAddress'));
        this.modalRef.hide();
        return;
      }
    }

    this.isCreatingOrder = true;

    // prep order object
    try {
      const order = this.prepareOrder();
      this.apiService.postNewOrder(order).subscribe((res) => {
        this.isCreatingOrder = false;
        this.handleOrderResponse(res);
      });
    } catch (err) {
      this.toastrService.error(this.translate('CART.orderNewError.message'), this.translate('CART.orderNewError.title'), { timeOut: 10000 });
      this.isCreatingOrder = false;
      this.modalRef.hide();
    }
  }

  async confirmAndClearCart() {
    this.modalRef.hide();
    await this.cartService.clearCart();

    this.toastrService.info('Kurven er slettet');
    await this.router.navigateByUrl('/');
  }

  async updateNotheggerAdjust() {
    this.userService.setAdminNotheggerAdjust(typeof this.adminNotheggerAdjust === 'number' ? this.adminNotheggerAdjust : undefined);
    await this.cartService.publishNewCart();
  }

  private prepareOrder(): CreateOrder {
    let priceTotalPlusFreight = this.orderTotalNoVat + this.freightTotal.basePrice;
    const fees: OrderFee[] = [];

    if (this.freightTotal.hasFronts) {
      priceTotalPlusFreight += this.freightTotal.frontsAddition;

      this.colours.forEach((colourFeeVariant) => {
        if (colourFeeVariant.totalAmount < this.freightSetting.purewood.colourStartup.threshold && this.user && this.user.company.freightTier) {
          fees.push({ [FeeTypes.purewoodColour]: this.freightSetting.purewood.colourStartup.fee[this.user.company.freightTier], variant: colourFeeVariant.colour });
        }
      });
    }

    if (this.oneDelivery > 0) {
      fees.push({ [FeeTypes.oneDelivery]: this.oneDelivery });
    }

    const order: CreateOrder = {
      userID: this.userService.getUserID(),
      order: this.cart,
      deliveryDate: undefined,
      // Disabled by 3256 Fjern ønske leveringsdato på kurv
      // deliveryDate: this.bsDeliveryDate ?? undefined, 
      orderDate: new Date(),
      status: 8,
      exVat: this.orderTotalNoVat,
      fees: fees,
      shipping: this.freightTotal,
      price: priceTotalPlusFreight,
      rekvisition: this.requisition,
      comments: this.comments,
      customs: this.freightTotal.told,
      externalShopOrders: this.createExternalOrdersForOrder(this.cart)
    };

    if (this.altLevering) {
      order.alternateDeliveryName = this.altLevAdr.name;
      order.alternateDeliveryAddress = this.altLevAdr.address;
      order.alternateDeliveryAddress2 = this.altLevAdr.address2;
      order.alternateDeliveryCity = this.altLevAdr.city;
      order.alternateDeliveryPostcode = parseInt(this.altLevAdr.postcode, 10);
    }

    if (this.ecoNumber && this.ecoNumber !== 0) {
      order.economicsNr = this.ecoNumber;
    }

    return order;
  }

  private async handleOrderResponse(res: Object) {
    if ("error" in res) {
      console.error('An error occured while creating order', res.error);
      this.toastrService.error(this.translate('CART.orderNewError.message'), this.translate('CART.orderNewError.title'), { timeOut: 10000 });
      this.modalRef.hide();
    } else {
      this.toastrService.success(this.translate('CART.orderSuccess.message'), this.translate('CART.orderSuccess.title'));
      if (!this.doNotDelete) {
        await this.cartService.clearCart();
      }
      this.modalRef.hide();
      if (!this.doNotDelete) {
        await this.router.navigateByUrl('/');
      }
      this.doNotDelete = false;
    }
  }

  private createExternalOrdersForOrder(items: ICartItem[]) {
    // Order items, which can be ordered externally
    const externalShopOrders: IExternalShopOrder[] = [];
    items.forEach((item) => {
      if (item.externalShopName) {
        let exists = false;
        externalShopOrders.forEach((externalShopOrder) => {
          if (externalShopOrder.shopName === item.externalShopName) {
            exists = true;
          }
        });
        if (!exists) {
          externalShopOrders.push({ shopName: item.externalShopName, attempts: 0 });
        }
      }
    });
    return externalShopOrders;
  }
}
